import React, { useEffect, useRef, useState } from "react";
import Table from "../../shared/tables/material_table";
import { useSnackbar, withSnackbar } from "notistack";
import { FiRefreshCw, FiPackage } from "react-icons/fi";
import Backdrop from "../../shared/backdrop";
import { ImInfo } from "react-icons/im";
import StockForm from "./_stock_form";
import { BsBookshelf, BsChatDots, BsPlusLg } from "react-icons/bs";
import NoteForm from "../../notes/_note_form";
import {
  Link,
  Badge,
  IconButton,
  Switch,
  styled,
  FormControl,
  Select,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Barcode from "../../order_items/_barcode";
// import AddToList from "./_add_to_packing_list_form";
import ShowItem from "../../order_items/show";
// import CreateShipment from "./_create_shipment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import pureIcons from "../../shared/pure_icons";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";
import {
  getFilterSelection,
  saveFilterSelection,
} from "../../shared/filter_selection";

const Stocked = () => {
  const history = useHistory();
  const _stockFormRef = useRef<any>();
  const _noteFormRef = useRef<any>();
  const _ShowItemRef = useRef<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<any>(false);
  const [open, setOpen] = useState<any>();
  const [rowData, setRowData] = useState<any>();
  const tempRowData = useRef<any>([]);
  const [orderState, setOrderState] = useState<boolean>(true);
  const tableRef = useRef<any>();
  const selectedValue = useRef<any>(
    getFilterSelection("hub-selected-filter") || "All"
  );
  const shippingOptions = useRef<any>([]);

  const reloadData = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const handleLockChange = (rowData) => {
    const source = localStorage.getItem("source");
    fetch(
      `/order_item_stages/${rowData.order_item_stage_id}/switch_lock.json?source=${source}`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          reloadData();
          enqueueSnackbar("successfully processed!", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(data.responseJSON.error, {
            variant: "error",
          });
        }
      });
  };

  const handleRoleFilterChange = (val) => {
    selectedValue.current = val;
    reloadData();
  };

  const columns = [
    {
      title: "",
      field: "image",
      sorting: false,
      filtering: false,
      editable: "never",
      render: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Badge
            badgeContent="DG"
            invisible={!row.dg}
            overlap="circular"
            color="error"
          >
            <img
              style={{ height: "60px", width: "60px", objectFit: "contain" }}
              alt={row.name}
              src={row.image}
            ></img>
          </Badge>
        </div>
      ),
    },
    {
      title: "Product Name",
      field: "name_short",
      sorting: false,
      filtering: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 400,
      },
      editable: "never",
    },
    {
      title: "QTY",
      field: "qty",
      sorting: false,
      filtering: false,
    },
    {
      title: "Note",
      field: "note",
      filtering: false,
      sorting: false,
      editable: "never",
      cellStyle: { whiteSpace: "normal", wordWrap: "break-word", width: "15%" },
    },
    {
      title: "Code",
      field: "redeem_code",
      sorting: false,
      filtering: false,
      editable: "never",
    },
    {
      title: "Tracking",
      field: "tracking",
      sorting: false,
      filtering: false,
      editable: "never",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 300,
      },
    },
    {
      title: "Shipping Method",
      field: "shipping_method",
      sorting: false,
      editable: "never",
      filtering: true,
      lookup: { Sea: "Sea", Others: "Others" },
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "13%",
      },
      filterComponent: () => (
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <Select
            value={selectedValue.current}
            onChange={(e) => {
              handleRoleFilterChange(e.target.value),
                (selectedValue.current = e.target.value);
              saveFilterSelection("hub-selected-filter", e.target.value);
            }}
          >
            {shippingOptions.current?.map((item) => {
              return (
                <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                  <FormControlLabel
                    value={item}
                    control={<Radio checked={selectedValue.current == item} />}
                    label={item}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ),
      render: (rowData) => <span>{rowData?.shipping_method}</span>,
    },
    {
      title: "Processed By",
      field: "who_did_it",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Purchased",
      field: "purchased_at",
      filtering: false,
      editable: "never",
      sorting: false,
    },
    {
      title: "Received",
      field: "created_at",
      filtering: false,
      editable: "never",
    },
  ];

  return (
    <div className="bg-white rounded">
      <Backdrop open={loading} />
      <NoteForm
        ref={_noteFormRef}
        newNoteChild={reloadData}
        rowData={rowData}
      />
      <ShowItem ref={_ShowItemRef} reloadData={reloadData} />
      <StockForm
        stock={true}
        ref={_stockFormRef}
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        reloadData={reloadData}
      />
      <Table
        title={`Items in Stock`}
        options={{
          toolbar: true,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          selection: false,
          searchAutoFocus: true,
        }}
        columns={columns}
        tableRef={tableRef}
        actions={[
          // {
          //   icon: () => (
          //     <>
          //       <span
          //         style={{
          //           position: "absolute",
          //           zIndex: 1,
          //           color: orderState ? "#315e5a" : "white",
          //           fontSize: "1rem",
          //           marginRight: orderState ? "1rem" : "-1rem",
          //         }}
          //       >
          //         {orderState ? "Sea" : "Sea"}
          //       </span>
          //       <IOSSwitch sx={{ m: 1 }} checked={orderState} />
          //     </>
          //   ),
          //   onClick: () => {
          //     switchAction();
          //   },
          //   isFreeAction: true,
          //   tooltip: "Shipping Options",
          // },
          {
            icon: pureIcons.FiRefreshCw,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          (rowData) => ({
            icon: () => <Barcode code={rowData.redeem_code} />,
            tooltip: <span>Barcode</span>,
            position: "row",
          }),
          (rowData) => ({
            tooltip: "Move to Shipment",
            icon: pureIcons.FiPackage,
            disabled: rowData.locked,
            onClick: (event, rowData) => {
              setOpen(true);
              setRowData(rowData);
            },
            position: "row",
          }),
          {
            icon: pureIcons.ImInfo,
            tooltip: <span>Show</span>,
            onClick: (event, rowData) => {
              const source = localStorage.getItem("source");
              if (_ShowItemRef.current) {
                _ShowItemRef.current.handleOpen(rowData.order_item_id, source);
              }
            },
            position: "row",
          },
          (rowData) => ({
            icon: rowData.locked ? pureIcons.LockOpenIcon : pureIcons.LockIcon,
            tooltip: rowData.locked ? <span>Unlock</span> : <span>Lock</span>,
            onClick: (event, rowData) => {
              window.confirm(
                `Are you sure you want to ${
                  rowData.locked ? "unlock" : "lock"
                } ${rowData.name} ?`
              ) && handleLockChange(rowData);
            },
            position: "row",
          }),
          {
            icon: pureIcons.BsChatDots,
            tooltip: <span>Notes</span>,
            onClick: (event, rowData) => {
              const source = localStorage.getItem("source");
              _noteFormRef.current.handleOpen(
                rowData.order_item_stage_id,
                source
              );
            },
            position: "row",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters?.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }
            const source = localStorage.getItem("source");
            const destination = localStorage.getItem("destination");
            let url = `/order_item_stages/stocked.json?source=${source}&location=${destination}&shipping_option=${selectedValue.current}`;
            url += "&per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    throw new Error(
                      "You need to sign in or sign up before continuing."
                    );
                  }
                }
                return response.json();
              })
              .then((result) => {
                if (result?.error) {
                  enqueueSnackbar(
                    "Something went wrong, you are redirected to home page",
                    {
                      variant: "error",
                    }
                  );

                  setTimeout(() => {
                    history.push("/");
                  }, 500);

                  return;
                }
                tempRowData.current = result?.products;
                shippingOptions.current = result?.shipping_methods;
                resolve({
                  data: result?.products,
                  page: result?.page - 1,
                  totalCount: result?.total,
                }),
                  window.breadcrumb.addBreadcrumb(result.breadcrumb);
              })
              .catch((error) => {
                if (
                  error.message ===
                  "You need to sign in or sign up before continuing."
                ) {
                  window.location.href = "/";
                } else {
                  console.error("Error:", error);
                }
              });
          })
        }
      />
    </div>
  );
};

export default withSnackbar(Stocked);
