import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Card, MenuItem, TextField } from "@material-ui/core";
import Select from "../../shared/autocomplete";
import Modal from "../../shared/modal/material_dialog";
import $ from "jquery";
import { useSnackbar, withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";

const AddToListForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<any>(false);
  const [shipping_method, setShipping_Method] = useState<any>([]);
  const [errors, setErrors] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const [packing_list, setPacking_List] = useState<any>([]);
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleOpen = (products) => {
    setProducts(products);
    props.setOpen(true);
    setShipping_Method(shipping_method);

    if (props.shipping_methods.length === 1) {
      setShipping_Method(props.shipping_methods[0]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const payload = {
      group_id: packing_list,
      products: props?.products,
      shipping_method:
        props.shipping_methods.length === 1
          ? props.shipping_methods[0]
          : props.shipping_methods,
    };
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");
    $.ajax({
      url: `/order_item_stages/create_packing_list.json?source=${source}&location=${destination}`,
      type: "POST",
      data: payload,
      dataType: "json",
      success: function (response) {
        setLoading(false);
        props.setOpen(false);
        props.callBackAddToList();
        if (response.success) {
          enqueueSnackbar("successfully processed!", {
            variant: "success",
          });
        }
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        setLoading(false);
      },
    });
  };

  const handleSelectCallBack = (data) => {
    setPacking_List(data.value);
    validate({ data });
  };

  const validate = (fieldValues = packing_list) => {
    let temp = { ...errors };
    temp.packing_list = fieldValues ? "" : "Required";
    setErrors({ errors: { ...temp } });
    if (fieldValues === packing_list)
      return Object.values(temp).every((x) => x === "");
  };

  const { classes } = props;
  const actions = (
    <Button
      variant="contained"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      type="submit"
      className={classes.button}
      onClick={handleSubmit}
    >
      Submit
    </Button>
  );

  const body = (
    <Card className={classes.root} style={{ minHeight: "30vh" }}>
      <div className="container pb-5">
        <p>
          You have selected {products?.length} item
          {products?.length > 1 ? "s" : ""} to add to an existing packing list
        </p>
        <div className="row pt-2">
          <Select
            name="packing_list"
            label="Packing List"
            url={`/order_item_stages/ready_to_ship.json?shipping_option=${
              props?.shipping_methods
            }&source=${localStorage.getItem("source") || ""}&location=${
              localStorage.getItem("destination") || ""
            }`}
            error={errors?.packing_list}
            callBack={handleSelectCallBack}
          />
        </div>
        {props.shipping_methods?.length > 1 && (
          <div className="row pt-3">
            <div className="col">
              <TextField
                label="Shipping Method"
                select
                fullWidth
                value={shipping_method || ""}
                {...(errors?.shipping_method && {
                  error: true,
                  helperText: errors.shipping_method,
                })}
                onChange={(event) => setShipping_Method(event.target.value)}
              >
                {shipping_method?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        )}
      </div>
    </Card>
  );

  return (
    <>
      <Backdrop open={loading} />
      <Modal
        disableBackdropClick
        isOpen={props.open}
        title="Add to Packing List"
        handleClose={handleClose}
        maxHeight="400%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
};

const useStyles = (theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
});

export default withSnackbar(withStyles(useStyles)(AddToListForm));
