import React, { useEffect, useRef, useState } from "react";
import Table from "../../shared/tables/material_table";
import ShippingForm from "./_shipping_form";
import {
  Badge,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  Select,
} from "@material-ui/core";
import ShowOrder from "../_show";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Moment from "moment";
import Backdrop from "../../shared/backdrop";
import AutoControlPanel from "./_auto_control_panel";
import pureIcons from "../../shared/pure_icons";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import { MenuItem } from "react-pro-sidebar";
import {
  getFilterSelection,
  saveFilterSelection,
} from "../../shared/filter_selection";

const Index = ({ orderState }: any) => {
  const [orders, setOrders] = useState<any>([]);
  const [working, setWorking] = useState<boolean>(false);
  const [total, setTotal] = useState<any>(null);
  const [muiTableKey, setMuiTableKey] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [order_number, setOrderNumber] = useState<any>("");
  const [orderStateValue, setOrderStateValue] = useState<any>(false);
  const [selectedRoles, setSelectedRoles] = useState<any>("");
  const shippingOptions = useRef<any>([]);

  const selectedValue = useRef<any>(
    getFilterSelection("hub-selected-filter") || "All"
  );
  const _tableRef = useRef<any>();
  const _ShippingForm = useRef<any>();
  const _ShowOrderRef = useRef<any>();
  const _AutoControlPanelRef = useRef<any>();

  const { enqueueSnackbar } = useSnackbar();

  const reloadData = (fullReload = false) => {
    if (fullReload) setMuiTableKey(muiTableKey + 1);

    _tableRef.current.onQueryChange();
  };

  const handleReviewStatus = (data: any) => {
    $.ajax({
      url: `/stages/${data.order_number}/new_stage_review`,
      type: "PUT",
      data: { invoice: data.invoice, stage: "purchased" },
      dataType: "json",
      success: function (response) {
        enqueueSnackbar("successfully updated!", {
          variant: "success",
        });
        reloadData();
      },
      error: function (response) {
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        setWorking(false);
      },
    });
  };

  const handleUpdate = (
    oldValue: any,
    newValue: any,
    columnDef: any,
    order_number: any,
    source: any
  ) => {
    if (oldValue === newValue || newValue === "" || newValue === null) return;

    let url, data;
    switch (columnDef.field) {
      case "invoice":
        url = `/order_item_stages/update_stage_tracking.json?source=${source}`;
        data = {
          tracking: oldValue,
          new_tracking: newValue,
          stage: "purchased",
        };
        break;
      case "eta":
        url = `/order_item_stages/update_stage.json?source=${source}`;
        data = { tracking: oldValue, eta: newValue, stage: "purchased" };
        break;
      case "total":
        url = `/order_item_stages/update_invoice_total.json?source=${source}`;
        data = {
          tracking: oldValue,
          total: newValue,
          order_number: order_number,
        };
        break;
      default:
    }

    setWorking(true);
    $.ajax({
      url: url,
      type: "PATCH",
      data: data,
      dataType: "json",
      success: function (response) {
        setWorking(false);
        reloadData();
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        setWorking(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const handleReturnToPreviousStage = (rowData) => {
    setWorking(true);
    $.ajax({
      url: `/order_item_stages/return_to_previous_stage`,
      type: "POST",
      data: {
        invoice: rowData.raw_invoice,
        source: rowData.source,
        order_number: rowData.order_number,
        vendor: rowData.vendor,
      },
      dataType: "json",
      success: function (response) {
        setWorking(false);
        reloadData();
        enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
      },
      error: function (response) {
        setWorking(false);
        enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
      },
    });
  };

  const handleRoleFilterChange = (val) => {
    setSelectedRoles(val);
    selectedValue.current = val;
    reloadData();
  };

  const columns = [
    {
      title: "Invoice",
      field: "invoice",
      sorting: false,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 400,
      },
      filtering: false,
    },
    {
      title: "Order Number",
      field: "order_number",
      sorting: false,
      filtering: false,
      editable: "never",
      render: (rowData) => (
        <Badge
          badgeContent={rowData.new_customer ? "new" : null}
          color="secondary"
        >
          {rowData.order_number}
        </Badge>
      ),
    },
    {
      title: "Vendor",
      field: "vendor",
      sorting: false,
      editable: "never",
      filtering: false,
    },
    {
      title: "Items",
      field: "products_count",
      sorting: false,
      editable: "never",
      cellStyle: { width: "2%" },
      filtering: false,
    },
    {
      title: "invoice Total",
      field: "total",
      sorting: false,
      filtering: false,
    },
    {
      title: "Shipping Method",
      field: "shipping_option",
      sorting: false,
      editable: "never",
      filtering: true,
      cellStyle: {
        whiteSpace: "normal",
        wordWrap: "break-word",
        width: "10%",
      },

      filterComponent: () => (
        <FormControl component="fieldset" style={{ width: "90%" }}>
          <Select
            value={selectedRoles || selectedValue.current}
            onChange={(e) => {
              handleRoleFilterChange(e.target.value),
                (selectedValue.current = e.target.value);
              saveFilterSelection("hub-selected-filter", e.target.value);
            }}
          >
            {shippingOptions.current?.map((item) => {
              return (
                <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                  <FormControlLabel
                    value={item}
                    control={<Radio checked={selectedValue.current == item} />}
                    label={item}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ),
      render: (rowData) => <span>{rowData?.shipping_option}</span>,
    },
    {
      title: "ETA",
      field: "eta",
      type: "date",
      filtering: false,
      dateSetting: { format: "dd/MM/yyyy" },
      render: (rowData) => {
        return rowData.flag ? (
          <div style={{ color: "#FF0000" }}>
            {Moment(rowData.eta).format("DD/MM/YYYY")}
          </div>
        ) : (
          <div>{Moment(rowData.eta).format("DD/MM/YYYY")}</div>
        );
      },
    },
    {
      title: "Time purchased",
      field: "created_at",
      editable: "never",
      filtering: false,
    },
    { title: "id", field: "id", hidden: true },
    { title: "group_id", field: "group_id", hidden: true },
    { title: "market_source", field: "market_source", hidden: true },
    { title: "source", field: "source", hidden: true },
    { title: "new_customer", field: "new_customer", hidden: true },
  ];

  return (
    <div className="bg-white rounded">
      <ShowOrder ref={_ShowOrderRef} />
      <Backdrop open={working} />
      <ShippingForm ref={_ShippingForm} reloadData={reloadData} />
      <AutoControlPanel ref={_AutoControlPanelRef} />
      <Table
        title={"Purchased Orders (Not shipped yet)"}
        key={muiTableKey}
        options={{
          filtering: true,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          searchAutoFocus: true,
          searchFieldStyle: { width: "102%" },
        }}
        localization={{
          toolbar: { searchPlaceholder: "invoice or order number" },
        }}
        columns={columns}
        tableRef={_tableRef}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve: any, reject) => {
              setTimeout(() => {
                if (
                  columnDef.field === "invoice_total" &&
                  (rowData.subtotal + rowData.subtotal * 0.025 < newValue ||
                    rowData.subtotal - rowData.subtotal * 0.1 > newValue)
                )
                  window.confirm(
                    `Invoice is different than subtotal (${rowData.subtotal.toFixed(
                      2
                    )})`
                  ) &&
                    handleUpdate(
                      rowData.raw_invoice,
                      newValue,
                      columnDef,
                      rowData.order_number,
                      rowData.source
                    );
                else
                  handleUpdate(
                    rowData.raw_invoice,
                    newValue,
                    columnDef,
                    rowData.order_number,
                    rowData.source
                  );
                resolve();
              }, 500);
            });
          },
        }}
        actions={[
          {
            icon: () => <pureIcons.Traffic />,
            onClick: () => {
              _AutoControlPanelRef.current.handleOpen();
            },
            isFreeAction: true,
            tooltip: "Auto control",
          },
          {
            icon: () => <pureIcons.FiRefreshCw />,
            onClick: () => {
              reloadData();
            },
            isFreeAction: true,
            tooltip: "Refresh",
          },
          (rowData) => ({
            icon: () => <pureIcons.FaShippingFast />,
            tooltip: <span>Ship {rowData.vendor}</span>,
            onClick: (event, rowData) => {
              _ShippingForm.current.handleOpen(
                rowData.raw_invoice,
                rowData.order_number,
                rowData.vendor,
                rowData.id,
                rowData.source,
                rowData.group_id,
                rowData.market_source,
                rowData.new_customer,
                rowData.shipping_option,
                selectedValue.current
              );
            },
            position: "row",
          }),

          (rowData) => ({
            icon: () => <pureIcons.MdPreview />,
            tooltip:
              rowData.is_reviewed === true ? (
                <span>Reviewed By: {rowData.reviewer}</span>
              ) : (
                <span>Mark as Reviewed</span>
              ),
            onClick: (event, rowData) => {
              window.confirm(
                `Are you sure you want to change status to be { REVIEWED } ?`
              ) && handleReviewStatus(rowData);
            },
            position: "row",
            disabled: rowData.is_reviewed === true,
          }),
          {
            icon: () => (
              <span style={{ padding: "0rem .5rem" }}>
                <AssignmentReturnIcon />
              </span>
            ),
            tooltip: "Return to previous stage",
            onClick: (event, rowData) => {
              window.confirm(
                `Are you sure you want to return ${rowData.invoice} to previous stage?`
              ) && handleReturnToPreviousStage(rowData);
            },
            position: "row",
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            var filters;
            if (query.filters.length > 0) {
              filters = query.filters.map((col) => ({
                field: col.column.field,
                value: col.value,
              }));
            }

            selectedValue.current == "Sea"
              ? setOrderStateValue(true)
              : setOrderStateValue(false);

            const destination = localStorage.getItem("destination");
            let url = `/stages/purchased.json?location=${destination}&shipping_option=${selectedValue.current}&`;
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.filters.length > 0) {
              url += "&filters=" + JSON.stringify(filters);
            }
            if (query.orderBy) {
              url += "&orderBy=" + query.orderBy.field;
              url += "&orderDirection=" + query.orderDirection;
            }
            if (query.search) {
              url += "&search=" + query.search;
            }
            fetch(url)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 401) {
                    throw new Error(
                      "You need to sign in or sign up before continuing."
                    );
                  }
                }
                return response.json();
              })
              .then((result) => {
                shippingOptions.current = result.shipping_methods;
                resolve({
                  data: result.invoices,
                  page: result.page - 1,
                  totalCount: result.total,
                });
                window.breadcrumb.addBreadcrumb(result.breadcrumb);
              })
              .catch((error) => {
                if (
                  error.message ===
                  "You need to sign in or sign up before continuing."
                ) {
                  window.location.href = "/";
                } else {
                  console.error("Error:", error);
                }
              });
          })
        }
      />
    </div>
  );
};

export default Index;
