import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
  CardActions,
  Box,
  Divider,
  TextField,
  Checkbox,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Modal from "../../shared/modal/material_dialog";
import Select from "../../shared/autocomplete";
import $ from "jquery";
import { useSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import { MdProductionQuantityLimits } from "react-icons/md";

export default function SetLimitForm({ product, callBackDeleteItem }: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [working, setWorking] = React.useState<boolean>(false);
  const [isRecurrent, setIsRecurrent] = React.useState<any>(false);
  const [remainingQty, setRemainingQty] = React.useState<any>("");
  const [productId, setProductId] = React.useState<any>("");
  const [resetAfterDays, setResetAfterDays] = React.useState<any>("");
  const [error, setError] = useState("");
  const [notFound, setNotFound] = useState<any>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Call the function to fetch data
    getProductId();
  }, []);

  const getProductId = () => {
    let code;
    if (product.url.match(/dp\/.*?(\?|\/)/gim)) {
      code = product.url
        .match(/dp\/.*?(\?|\/)/gim)[0]
        .replace("dp/", "")
        .replace("?", "")
        .replace("/", "");
    } else if (product.url.match(/gp\/product\/.*?(\?|\/)/gim)) {
      code = product.url
        .match(/gp\/product\/.*?(\?|\/)/gim)[0]
        .replace("gp/product/", "")
        .replace("?", "")
        .replace("/", "");
    } else if (product.url.match(/gp\/aw\/d\/.*?(\?|\/|#)/gim)) {
      code = product.url
        .match(/gp\/aw\/d\/.*?(\?|\/|#)/gim)[0]
        .replace("gp/aw/d/", "")
        .replace("/", "")
        .replace("#", "")
        .replace("?", "");
    } else if (product.url.match(/gp\/aw\/d\/.*/gim)) {
      code = product.url.match(/gp\/aw\/d\/.*/gim)[0].replace("gp/aw/d/", "");
    } else if (product.url.match(/dp\/.*?$/gim)) {
      code = product.url.match(/dp\/.*?$/gim)[0].replace("dp/", "");
    } else if (product.url.match(/gp\/product\/.*/gim)) {
      code = product.url
        .match(/gp\/product\/.*/gim)[0]
        .replace("gp/product/", "");
    } else {
      setNotFound(true);
    }
    setProductId(code);
  };

  const body = (
    <Card>
      {notFound ? (
        <h4 style={{ padding: "1rem", textAlign: "center" }}>
          ID IS NOT FOUND
        </h4>
      ) : (
        <CardContent>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Brand</Typography>

            <TextField
              style={{
                minWidth: "40%",
                marginRight: "32%",
                marginLeft: "auto",
              }}
              disabled
              size="small"
              value={product.brand}
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Product Name</Typography>

            <TextField
              style={{
                minWidth: "40%",
                marginRight: "32%",
                marginLeft: "auto",
              }}
              disabled
              size="small"
              value={product.name_50}
            />
          </Box>

          {/* <Divider style={{ margin: "20px 0px" }} /> */}

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Product Id</Typography>

            <TextField
              name="name_ku"
              style={{
                minWidth: "40%",
                marginRight: "32%",
                marginLeft: "auto",
              }}
              disabled
              size="small"
              value={productId}
            />
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Remaining Quantity</Typography>

            <TextField
              style={{
                minWidth: "40%",
                marginRight: "32%",
                marginLeft: "auto",
              }}
              size="small"
              placeholder="Remaining Quantity"
              value={remainingQty}
              onChange={(ev) => setRemainingQty(ev.target.value)}
              type="number"
              error={!!error} // Set error prop based on validation
              helperText={
                error && !remainingQty ? "This field is required." : ""
              }
            />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
          >
            <Typography>Reset After Days</Typography>

            <TextField
              style={{
                minWidth: "40%",
                marginRight: "32%",
                marginLeft: "auto",
              }}
              size="small"
              placeholder="Reset After Days"
              value={resetAfterDays}
              onChange={(ev) => setResetAfterDays(ev.target.value)}
              type="number"
              error={!!error} // Set error prop based on validation
              helperText={
                error && !resetAfterDays ? "This field is required." : ""
              }
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Is Recurrent</Typography>

            <Checkbox
              style={{ marginRight: "68%", marginLeft: "auto" }}
              placeholder="Is Recurrent"
              checked={isRecurrent}
              onChange={(ev) => setIsRecurrent(ev.target.checked)}
            />
          </Box>
        </CardContent>
      )}
    </Card>
  );

  const handleClose = () => {
    if (working) return;
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setWorking(true);
    if (!remainingQty || !resetAfterDays) {
      setError("Both fields are mandatory.");
      setWorking(false);
      return;
    }
    setError("");
    const data = {
      brand: product?.brand,
      product_id: productId,
      name: product?.name,
      remaining_quantity: remainingQty,
      reset_after_days: resetAfterDays,
      is_recurrent: isRecurrent,
    };
    $.ajax({
      url: `/order_items/set_product_limit`,
      type: "POST",
      data: data,
      dataType: "json",
      success: function (response) {
        setWorking(false);
        enqueueSnackbar(response.message, { variant: "success" });
        handleClose();
      },
      error: function (response) {
        setWorking(false);
        enqueueSnackbar(response.responseJSON.error, { variant: "error" });
      },
    });
  };

  const actions = working ? (
    <CircularProgress className={classes.button} />
  ) : (
    <>
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        type="submit"
        className={classes.button}
        disabled={working || notFound}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );

  return (
    <>
      <IconButton aria-label="delete" onClick={handleOpen}>
        <MdProductionQuantityLimits style={{ fontSize: 30 }} />
      </IconButton>
      <Backdrop open={working} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={`Set Limit For Product`}
        handleClose={handleClose}
        maxHeight="60%"
        maxWidth="md"
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: 10,
    margin: 5,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    objectFit: "contain",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  button: {},
}));
